.my-react-select__input-container{
color:#92929f!important;
}
.status-Inactive{
    color:skyblue
}
.status-Active {
    color: green;
}
  
.status-Registered {
    color: orange;
}
  
.status-Expired {
    color: red;
}

.dropdown-menu .dropdown-item {
    width: 100%; /* Ensure the dropdown item takes the full width */
    transition: background-color 0.3s ease; /* Smooth transition for hover */
  }
  
  /* Add your hover effect styles */
  .dropdown-menu .dropdown-item:hover {
    background-color: #d4edda; /* Light green background on hover */
    color: #155724; /* Dark green text color on hover */
  }
  
.status-Requested{
    color:brown
}
.status-Completed {
    color: green;
  }
  
  .status-Closed {
    color: orange;
  }
  
 
  .status-Expired {
    color: red;
  }

#topheader{
    padding-bottom: 10px;
}
.card-toolbar{
    float:right
}
#link{
    color: var(--kt-text-muted) !important;
}

.rotate-up {
    transform: rotate(35deg); /* Adjust the angle as needed */
    display: inline-block; /* Ensure the span can be transformed */
  }
  
  .rotate-down {
    transform: rotate(225deg); /* Adjust the angle as needed */
    display: inline-block; /* Ensure the span can be transformed */
  }
  